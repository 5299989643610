import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ServerAutoSuggest from "../ServerAutoSuggest";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { meditationPlacesUri } = getMandatoryEnv(["meditationPlacesUri"]);
const dflt = [
  {
    id: "others",
    name: "Others",
  },
];

export const fetchMeditationPlace = (place) => {
  if (place === "Others") return Promise.resolve(dflt[0]);
  const result = fetch(
    `${meditationPlacesUri}/mediation-places?page=0&limit=2000&centerType=ashram&meditationPlaceName=${place}`
  )
    .then((R) => R?.json())
    .then((res) => {
      return res?.meditationCenters?.find((i) => i?.name === place) || {};
    })
    .catch(() => {});
  return result || {};
};

function getConfigs({ includeOnly }) {
  const config = {
    dflt: includeOnly ? dflt.filter(includeOnly) : [],
    fetch: (place) => {
      return fetchMeditationPlace(place);
    },
    // Trigger suggestions
    getSuggestionValue: (suggestion) => {
      return suggestion.name;
    },
    // Render Each Option
    renderSuggestion: (suggestion) => suggestion.name,
    api: (itrimValue) => {
      if (itrimValue?.length > 2)
        return `${meditationPlacesUri}/mediation-places?page=0&limit=2000&centerType=ashram&meditationPlaceName=${itrimValue}`;
      return null;
    },
    data2results: (data) => {
      const meditationCenters = data?.meditationCenters || [];
      const results = [...meditationCenters, ...dflt];
      return results;
    },
  };

  return config;
}

const MeditationPlace = ({ includeOnly, ...restprops }) => {
  return (
    <ServerAutoSuggest
      {...restprops}
      config={useMemo(() => getConfigs({ includeOnly }), [includeOnly])}
    />
  );
};

MeditationPlace.propTypes = {
  includeOnly: PropTypes.func,
};
MeditationPlace.defaultProps = {
  includeOnly: null,
};

export default MeditationPlace;
