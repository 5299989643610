let firebaseAuthConfig;
let mySrcmConfig;
let firebaseStoreConfig;
let donationServiceConfig;
let myDomain;
let siteUrl;
let siteDomain;
let sitePathPrefix;
let cloudFunctionBookings;
let kmcFormConfig;
let forestsSrcmApiConfig;
let forestsPostApiConfig;
let hfnPrimaryApiUri;
let eventsViewMoreBaseUri;
let captchaClientKey;
let dayVisitorLink;
let serviceApartmentLink;
let heartfulnessinstituteDonationUrl;
let meditationPlacesUri;
let paymentServiceUrl;
let razorPayCheckoutLib;

const prod = process.env.GATSBY_ENV === "prod";
const stagingEnv = prod ? {} : require("./loadEnvStaging")();

try {
  firebaseAuthConfig = JSON.parse(process.env.GATSBY_FIREBASE_AUTH_CONFIG);
} catch (e) {
  if (!prod) {
    firebaseAuthConfig = JSON.parse(stagingEnv.GATSBY_FIREBASE_AUTH_CONFIG);
  }
}
try {
  cloudFunctionBookings = process.env.GATSBY_CLOUD_FUNC_BOOKINGS;
  if (!cloudFunctionBookings) {
    throw Error("error");
  }
} catch (e) {
  if (!prod) {
    cloudFunctionBookings = stagingEnv.GATSBY_CLOUD_FUNC_BOOKINGS;
  }
}
try {
  mySrcmConfig = {
    ...JSON.parse(process.env.GATSBY_MYSRCM_CONFIG),
    baseClient: process.env.GATSBY_MYSRCM_BASE_CLIENTID,
    eventsClient: process.env.GATSBY_MYSRCM_EVENTS_CLIENTID,
    firestoreClient: stagingEnv.GATSBY_MYSRCM_FIRESTORE_CLIENTID,
  };
} catch (e) {
  if (!prod) {
    mySrcmConfig = {
      ...JSON.parse(stagingEnv.GATSBY_MYSRCM_CONFIG),
      baseClient: stagingEnv.GATSBY_MYSRCM_BASE_CLIENTID,
      eventsClient: stagingEnv.GATSBY_MYSRCM_EVENTS_CLIENTID,
      firestoreClient: stagingEnv.GATSBY_MYSRCM_FIRESTORE_CLIENTID,
    };
  }
}

try {
  firebaseStoreConfig = JSON.parse(process.env.GATSBY_FIREBASE_STORE_CONFIG);
} catch (e) {
  if (!prod) {
    firebaseStoreConfig = JSON.parse(stagingEnv.GATSBY_FIREBASE_STORE_CONFIG);
  }
}

try {
  heartfulnessinstituteDonationUrl =
    process.env.GATSBY_HEARTFULNESSINSTITUTE_DONATION_URL;
  if (!process.env.GATSBY_HEARTFULNESSINSTITUTE_DONATION_URL) {
    throw Error("GATSBY_HEARTFULNESSINSTITUTE_DONATION_URL not set");
  }
} catch (e) {
  if (!prod) {
    heartfulnessinstituteDonationUrl =
      stagingEnv.GATSBY_HEARTFULNESSINSTITUTE_DONATION_URL;
  }
}

try {
  hfnPrimaryApiUri = process.env.GATSBY_HEARTFULNESSORG_PRIMARY_URI;
  if (!process.env.GATSBY_DONATION_SERVICE) {
    throw Error("GATSBY_HEARTFULNESSORG_PRIMARY_URI not set");
  }
} catch (e) {
  if (!prod) {
    hfnPrimaryApiUri = stagingEnv.GATSBY_HEARTFULNESSORG_PRIMARY_URI;
  }
}
try {
  captchaClientKey = process.env.GATSBY_CAPTCHA_CLIENT_KEY;
  if (!process.env.GATSBY_DONATION_SERVICE) {
    throw Error("GATSBY_CAPTCHA_CLIENT_KEY not set");
  }
} catch (e) {
  if (!prod) {
    captchaClientKey = stagingEnv.GATSBY_CAPTCHA_CLIENT_KEY;
  }
}
try {
  meditationPlacesUri = process.env.GATSBY_MEDITATION_PLACES_URI;
  if (!process.env.GATSBY_MEDITATION_PLACES_URI) {
    throw Error("GATSBY_MEDITATION_PLACES_URI not set");
  }
} catch (e) {
  if (!prod) {
    meditationPlacesUri = stagingEnv.GATSBY_MEDITATION_PLACES_URI;
  }
}
try {
  paymentServiceUrl = process.env.GATSBY_PAYMENTS_SERVICE_URL;
  if (!process.env.GATSBY_DONATION_SERVICE) {
    throw Error("GATSBY_PAYMENTS_SERVICE_URL not set");
  }
} catch (e) {
  if (!prod) {
    paymentServiceUrl = stagingEnv.GATSBY_PAYMENTS_SERVICE_URL;
  }
}
try {
  razorPayCheckoutLib = process.env.GATSBY_RAZORPAY_CHECKOUT_LIB;
  if (!process.env.GATSBY_DONATION_SERVICE) {
    throw Error("GATSBY_RAZORPAY_CHECKOUT_LIB not set");
  }
} catch (e) {
  if (!prod) {
    razorPayCheckoutLib = stagingEnv.GATSBY_RAZORPAY_CHECKOUT_LIB;
  }
}
try {
  dayVisitorLink = process.env.GATSBY_DAY_VISITOR_REGISTER_URL;
  if (!process.env.GATSBY_DONATION_SERVICE) {
    throw Error("GATSBY_DAY_VISITOR_REGISTER_URL not set");
  }
} catch (e) {
  if (!prod) {
    dayVisitorLink = stagingEnv.GATSBY_DAY_VISITOR_REGISTER_URL;
  }
}
try {
  serviceApartmentLink = process.env.GATSBY_SERVICE_APARTMENT_REGISTER_LINK;
  if (!process.env.GATSBY_DONATION_SERVICE) {
    throw Error("GATSBY_SERVICE_APARTMENT_REGISTER_LINK not set");
  }
} catch (e) {
  if (!prod) {
    serviceApartmentLink = stagingEnv.GATSBY_SERVICE_APARTMENT_REGISTER_LINK;
  }
}
try {
  eventsViewMoreBaseUri = process.env.GATSBY_EVENTS_VIEWMORE;
  if (!process.env.GATSBY_DONATION_SERVICE) {
    throw Error("GATSBY_EVENTS_VIEWMORE not set");
  }
} catch (e) {
  if (!prod) {
    eventsViewMoreBaseUri = stagingEnv.GATSBY_EVENTS_VIEWMORE;
  }
}
try {
  let pp = process.env.GATSBY_DOMAIN_PATHPREFIX || "";
  if (pp.trim() === "/") {
    pp = "";
  } else {
    pp = pp.trim().replace(/\/$/, "");
    if (pp) {
      pp = pp.replace(/^\//, "").replace(/^/, "/");
    }
  }
  siteDomain = process.env.GATSBY_DOMAIN;
  sitePathPrefix = pp;
  siteUrl = `https://${process.env.GATSBY_DOMAIN}${pp}`;
  if (!siteDomain) {
    throw Error("SITE DOMAIN not set");
  }
} catch (e) {
  if (!prod) {
    siteDomain = "localhost:8000";
    sitePathPrefix = "/";
    siteUrl = "http://localhost:8000";
  } else {
    throw e;
  }
}
let wpGrapqlUrl;
try {
  wpGrapqlUrl = process.env.GATSBY_DONATIONS_WORDPRESS_URL;
  if (!process.env.GATSBY_DONATION_SERVICE) {
    throw Error("GATSBY_DONATION_SERVICE not set");
  }
  kmcFormConfig = { formUrl: process.env.GATSBY_KMC_FORM_URL };
  forestsSrcmApiConfig = {
    forestsSrcmApi: process.env.GATSBY_FORESTS_SCRMAPI_URL,
  };
  forestsPostApiConfig = { postApiUrl: process.env.GATSBY_FORESTS_POST_API };
  donationServiceConfig = {
    url: process.env.GATSBY_DONATION_SERVICE,
    staticConf: process.env.GATSBY_DONATION_STATICCONF,
    captchaKey: process.env.GATSBY_CAPTCHA_KEY,
    digioRecurringUri: process.env.GATSBY_INDIA_RECURRING_URI,
    digioPaymentUrl: process.env.GATSBY_DIGIO_PAYMENT_URL,
    digioResultUri: process.env.GATSBY_DIGIO_PAYMENT_RESULT_URI,
    everyDropcountEmailUri: process.env.GATSBY_EVERY_DROP_COUNT_EMAIL_URI,
    moneriRedirectAckUri: process.env.GATSBY_MONERIS_REDIRECT_ACK_URI,
    monerisPaymentGatewayLib: process.env.GATSBY_MONERIS_PAYMENT_GATEWAY_LIB,
    donationClassicWpUrl: `https://${wpGrapqlUrl}`,
    siteDomain,
    siteUrl,
    sitePathPrefix,
    authorizeDotNetMakePaymentUri:
      process.env.GATSBY_AUTHORIZE_DOT_NET_MAKEPAYMENT_URI,
    authorizeDotNetRedirectAckUri:
      process.env.GATSBY_AUTHORIZE_DOT_NET_REDIRECT_ACK_URI_URI,
    authorizeDotNetPaymentGatewayLib:
      process.env.GATSBY_AUTHORIZE_DOT_NET_PAYMENT_GATEWAY_LIB,
    authorizeDotNetSrcmClientKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_SRCM_CLIENT_KEY,
    authorizeDotNetSmsfClientKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_SMSF_CLIENT_KEY,
    authorizeDotNetHiClientKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_HI_CLIENT_KEY,
    authorizeDotNetSrcmApiLoginKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_SRCM_API_LOGIN_KEY,
    authorizeDotNetSmsfApiLoginKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_SMSF_API_LOGIN_KEY,
    authorizeDotNetHiApiLoginKey:
      process.env.GATSBY_AUTHORIZE_DOT_NET_HI_API_LOGIN_KEY,
    smsfContactEmailId: process.env.GATSBY_SMSF_CONTACT_EMAILID,
    srcmContactEmailId: process.env.GATSBY_SRCM_CONTACT_EMAILID,
    contactFormCaptchaKey: process.env.GATSBY_CONTACT_FORM_CAPTCHA_KEY,
    gatsbyDonationRecurringServiceBaseUri:
      process.env.GATSBY_DONATION_RECURRING_SERVICE_BASE_URI,
    authorizeDotNetRecurringPreSubscribe:
      process.env.GATSBY_AUTHORIZE_DOT_NET_RECURRING_PRESUBSCRIBE,
    offlineDonationUri: process.env.GATSBY_OFFLINE_DONATION,
    heartfulnessorgDonationLink:
      process.env.GATSBY_HEARTFULNESSORG_DONATION_LINK,
  };
} catch (e) {
  if (!prod) {
    wpGrapqlUrl = stagingEnv.GATSBY_DONATIONS_WORDPRESS_URL;
    if (!stagingEnv.GATSBY_DONATION_SERVICE) {
      throw Error("GATSBY_DONATION_SERVICE not set");
    }
    kmcFormConfig = { formUrl: stagingEnv.GATSBY_KMC_FORM_URL };
    forestsSrcmApiConfig = {
      forestsSrcmApi: stagingEnv.GATSBY_FORESTS_SCRMAPI_URL,
    };
    forestsPostApiConfig = { postApiUrl: stagingEnv.GATSBY_FORESTS_POST_API };
    donationServiceConfig = {
      url: stagingEnv.GATSBY_DONATION_SERVICE,
      staticConf: stagingEnv.GATSBY_DONATION_STATICCONF,
      captchaKey: stagingEnv.GATSBY_CAPTCHA_KEY,
      digioRecurringUri: stagingEnv.GATSBY_INDIA_RECURRING_URI,
      digioPaymentUrl: stagingEnv.GATSBY_DIGIO_PAYMENT_URL,
      digioResultUri: stagingEnv.GATSBY_DIGIO_PAYMENT_RESULT_URI,
      everyDropcountEmailUri: stagingEnv.GATSBY_EVERY_DROP_COUNT_EMAIL_URI,
      moneriRedirectAckUri: stagingEnv.GATSBY_MONERIS_REDIRECT_ACK_URI,
      monerisPaymentGatewayLib: stagingEnv.GATSBY_MONERIS_PAYMENT_GATEWAY_LIB,
      donationClassicWpUrl: `https://${wpGrapqlUrl}`,
      siteDomain,
      siteUrl,
      sitePathPrefix,
      authorizeDotNetMakePaymentUri:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_MAKEPAYMENT_URI,
      authorizeDotNetRedirectAckUri:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_REDIRECT_ACK_URI_URI,
      authorizeDotNetPaymentGatewayLib:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_PAYMENT_GATEWAY_LIB,
      authorizeDotNetSrcmClientKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_SRCM_CLIENT_KEY,
      authorizeDotNetSmsfClientKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_SMSF_CLIENT_KEY,
      authorizeDotNetHiClientKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_HI_CLIENT_KEY,
      authorizeDotNetSrcmApiLoginKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_SRCM_API_LOGIN_KEY,
      authorizeDotNetSmsfApiLoginKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_SMSF_API_LOGIN_KEY,
      authorizeDotNetHiApiLoginKey:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_HI_API_LOGIN_KEY,
      smsfContactEmailId: stagingEnv.GATSBY_SMSF_CONTACT_EMAILID,
      srcmContactEmailId: stagingEnv.GATSBY_SRCM_CONTACT_EMAILID,
      contactFormCaptchaKey: stagingEnv.GATSBY_CONTACT_FORM_CAPTCHA_KEY,
      gatsbyDonationRecurringServiceBaseUri:
        stagingEnv.GATSBY_DONATION_RECURRING_SERVICE_BASE_URI,
      authorizeDotNetRecurringPreSubscribe:
        stagingEnv.GATSBY_AUTHORIZE_DOT_NET_RECURRING_PRESUBSCRIBE,
      offlineDonationUri: stagingEnv.GATSBY_OFFLINE_DONATION,
      heartfulnessorgDonationLink:
        stagingEnv.GATSBY_HEARTFULNESSORG_DONATION_LINK,
    };
  } else {
    throw Error(e);
  }
}
try {
  myDomain = `https://${process.env.GATSBY_DOMAIN}`;
} catch {
  myDomain = "";
}
if (!prod && (!myDomain || myDomain === "https://undefined")) {
  myDomain = "http://localhost:8000";
}
let domainReg;
let domainApps;
let domainDonations;
let domainMy;
let eventsStaticConfig;
let kanhaStaticConfig;
let authStaticConfig;
let kanhaOMS;
let paymentService;
let domainKanha;
domainReg = `https://${process.env.GATSBY_DOMAIN_REG}`;
domainApps = `https://${process.env.GATSBY_DOMAIN_APPS}`;
domainDonations = `https://${process.env.GATSBY_DOMAIN_DONATIONS}`;
domainMy = `https://${process.env.GATSBY_DOMAIN_MY}`;
eventsStaticConfig = process.env.GATSBY_EVENTS_STATICCONF;
kanhaStaticConfig = process.env.GATSBY_KANHA_SHANTIVANAM_STATICCONF;
authStaticConfig = process.env.GATSBY_AUTH_STATICCONF;
kanhaOMS = process.env.GATSBY_ORDER_MANAGEMENT_SERVICE;
paymentService = process.env.GATSBY_OMS;
domainKanha = `https://${process.env.GATSBY_DOMAIN_KANHA}`;

if (!prod) {
  if (!domainReg || domainReg === "https://undefined") {
    domainReg = `https://${stagingEnv.GATSBY_DOMAIN_REG}`;
  }
  if (!domainApps || domainApps === "https://undefined") {
    domainApps = `https://${stagingEnv.GATSBY_DOMAIN_APPS}`;
  }
  if (!domainDonations || domainDonations === "https://undefined") {
    domainDonations = `https://${stagingEnv.GATSBY_DOMAIN_DONATIONS}`;
  }
  if (!domainMy || domainMy === "https://undefined") {
    domainMy = `https://${stagingEnv.GATSBY_DOMAIN_MY}`;
  }
  if (!domainKanha || domainKanha === "https://undefined") {
    domainKanha = `https://${stagingEnv.GATSBY_DOMAIN_KANHA}`;
  }
  if (!eventsStaticConfig) {
    eventsStaticConfig = stagingEnv.GATSBY_EVENTS_STATICCONF;
  }
  if (!kanhaStaticConfig) {
    kanhaStaticConfig = stagingEnv.GATSBY_KANHA_SHANTIVANAM_STATICCONF;
  }
  if (!authStaticConfig) {
    authStaticConfig = stagingEnv.GATSBY_AUTH_STATICCONF;
  }
  if (!kanhaOMS) {
    kanhaOMS = stagingEnv.GATSBY_ORDER_MANAGEMENT_SERVICE;
  }
  if (!paymentService) {
    paymentService = stagingEnv.GATSBY_OMS;
  }
}
const getMandatoryEnv = (l = []) => {
  const config = {
    donationServiceConfig,
    firebaseAuthConfig,
    firebaseStoreConfig,
    mySrcmConfig,
    domainReg,
    domainApps,
    domainDonations,
    domainMy,
    wpGrapqlUrl,
    siteUrl,
    cloudFunctionBookings,
    eventsStaticConfig,
    kanhaStaticConfig,
    authStaticConfig,
    kanhaOMS,
    kmcFormConfig,
    forestsSrcmApiConfig,
    forestsPostApiConfig,
    paymentService,
    domainKanha,
    hfnPrimaryApiUri,
    eventsViewMoreBaseUri,
    captchaClientKey,
    heartfulnessinstituteDonationUrl,
    dayVisitorLink,
    serviceApartmentLink,
    meditationPlacesUri,
    paymentServiceUrl,
    razorPayCheckoutLib,
  };
  l.forEach((element) => {
    if (!config[element]) {
      throw Error(`ERROR: CONFIG ${element} IS MISSING`);
    }
  });
  return config;
};

module.exports = getMandatoryEnv;
