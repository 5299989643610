import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faGlobe,
  faFile,
  faRupeeSign,
  faKey,
  faIdCardAlt,
  faHome,
  faArrowLeft,
  faPhone,
  faEnvelope,
  faUser,
  faMoneyBill,
  faTimes,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faStar,
  faStarOfDavid,
  faUserEdit,
  faTrash,
  faPencilAlt,
  faDollarSign,
  faIdBadge,
  faEuroSign,
  faPowerOff,
  faCalendar,
  faExclamationCircle,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faGlobe,
  faFile,
  faRupeeSign,
  faKey,
  faIdCardAlt,
  faHome,
  faArrowLeft,
  faPhone,
  faEnvelope,
  faUser,
  faMoneyBill,
  faTimes,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faStar,
  faStarOfDavid,
  faUserEdit,
  faTrash,
  faPencilAlt,
  faDollarSign,
  faPowerOff,
  faEuroSign,
  faIdBadge,
  faCalendar,
  faExclamationCircle,
  faMapPin
);
