import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

let check = "DEV - localhost";
try {
  check = process.env.GATSBY_BANNER || "DEV-localhost";
} catch (e) {
  check = "DEV-Localhost";
}

const divStyle = {
  position: "fixed",
  zIndex: 1001,
  top: 0,
  right: 40,
  opacity: 0.8,
  color: "black",
  background: "yellow",
  padding: "6px",
  fontSize: "1.2rem",
  fontWeight: "500",
};

const WrapPage = ({ element }) => (
  <div>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
    </Helmet>
    {element}
    {check !== "PROD" && !!check && <div style={divStyle}>{check}</div>}
  </div>
);

WrapPage.propTypes = {
  element: PropTypes.element.isRequired,
};

export default WrapPage;
